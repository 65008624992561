import { ConfigStateService, PagedResultDto } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ParsedGeocodedAddress } from '@flyguys/forms';
import {
  AvailabilityService,
  CountriesService,
  StatesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { AuthServerService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/authServer.service';
import { EthnicitiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/ethnicities.service';
import { GenderService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/genders.service';
import {
  AvailabilityDto,
  CountriesDto,
  EthnicityDto,
  GenderDto,
  GetAvailabilitiesInput,
  GetCountryInput,
  GetStateInput,
  StatesDto,
  enumState,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { ColumnAction } from 'projects/flyguys-pilot/src/app/components/columns/components/column-actions/column-actions.component';
import { DataValidationValidators } from 'projects/flyguys-pilot/src/app/enums/wizard';
import {
  PilotDto,
  PilotUpdateDto,
} from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { finalize, tap } from 'rxjs/operators';
import {
  IdentityUserDto,
  IdentityUserService,
  IdentityUserUpdateDto,
} from '@volo/abp.ng.identity/proxy';

@Component({
  selector: 'app-pilot-info-card',
  templateUrl: './pilot-info-card.component.html',
  styleUrls: ['./pilot-info-card.component.scss'],
})
export class PilotInfoCardComponent implements OnInit {
  @ViewChild('modal') modal: TemplateRef<any>;
  @Input() pilot: PilotDto;

  availabilities: PagedResultDto<AvailabilityDto> = {
    items: [],
    totalCount: 0,
  };

  dataCountries: PagedResultDto<CountriesDto> = {
    items: [],
    totalCount: 0,
  };

  dataStates: PagedResultDto<StatesDto> = {
    items: [],
    totalCount: 0,
  };

  isModalBusy = false;

  isModalOpen = false;

  form: FormGroup;

  availabilitiesDict: { [id: string]: string } = {};

  image: any;
  genders: GenderDto[];
  ethnicities: EthnicityDto[];
  allowedCountries: string[] = [];

  constructor(
    public readonly availabilityService: AvailabilityService,
    public readonly pilotService: PilotService,
    public readonly authServerService: AuthServerService,
    private config: ConfigStateService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private genderService: GenderService,
    private ethnicitiesService: EthnicitiesService,
    private toaster: ToasterService,
    public readonly countriesService: CountriesService,
    public readonly statesService: StatesService,
    private identityService: IdentityUserService,
  ) {}

  ngOnInit() {
    this.getCountries();
    this.getStates();

    this.genderService.GetGenders().subscribe(res => (this.genders = res.items));
    this.ethnicitiesService.GetEthnicities().subscribe(res => (this.ethnicities = res.items));

    this.getPilotAvailabilityValues();
    this.buildForm();

    const currentUser = this.config.getOne('currentUser');
    if (currentUser.id) {
      this.authServerService.GetProfilePicture(currentUser.id).subscribe(res => {
        this.image = `data:image/png;base64, ${res.fileContent}`;
      });
    }
  }

  getCountries() {
    const request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetCountryInput;
    this.countriesService.getList(request).subscribe({
      next: (data: PagedResultDto<CountriesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataCountries = data;
          this.allowedCountries = data.items?.map(c => c.code);
        }
      },
      error: err => console.log(`Error on getting Countries: ${JSON.stringify(err)}`),
    });
  }

  getStates() {
    const request = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    } as GetStateInput;
    this.statesService.getList(request).subscribe({
      next: (data: PagedResultDto<StatesDto>) => {
        if (data?.totalCount !== 0) {
          this.dataStates = data;
        }
      },
      error: err => console.log(`Error on getting States: ${+JSON.stringify(err)}`),
    });
  }

  hideForm() {
    this.dialog.closeAll();
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.dialog.open(this.modal, {
      width: '650px',
      disableClose: true,
      autoFocus: false,
    });

    this.validateForm(this.form);
  }

  buildForm() {
    this.setStatesFor(this.pilot.addressCountryId);
    this.form = this.fb.group({
      firstName: [this.pilot.firstName ?? null, [Validators.required, Validators.maxLength(100)]],
      lastName: [this.pilot.lastName ?? null, [Validators.required, Validators.maxLength(100)]],
      address: [this.pilot.address ?? null, [Validators.maxLength(500)]],
      addressStreet: [this.pilot.addressStreet ?? null, [Validators.maxLength(500)]],
      addressCity: [this.pilot.addressCity ?? null, [Validators.maxLength(500)]],
      addressState: [this.pilot.addressState ?? null, [Validators.maxLength(500)]],
      addressStateId: [this.pilot.addressStateId ?? null, [Validators.maxLength(500)]],
      addressCountry: [this.pilot.addressCountry ?? null, [Validators.maxLength(500)]],
      addressCountryId: [this.pilot.addressCountryId ?? null, [Validators.maxLength(500)]],
      addressZipCode: [this.pilot.addressZipCode ?? null, [Validators.maxLength(500)]],
      addressLat: [this.pilot.addressLat ?? null, [Validators.maxLength(500)]],
      addressLng: [this.pilot.addressLng ?? null, [Validators.maxLength(500)]],
      secondaryAddress: [this.pilot.secondaryAddress ?? null, [Validators.maxLength(500)]],
      phone: [this.pilot.phone ?? null, [Validators.required, Validators.maxLength(50)]],
      secondaryPhone: [this.pilot.secondaryPhone ?? null, [Validators.maxLength(50)]],
      part107Number: [
        this.pilot.part107Number ?? null,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(DataValidationValidators.FAAMaxLength),
        ],
      ],
      availabilityId: [this.pilot.availabilityId ?? null, []],
      email: [
        { value: this.pilot.email ?? null, disabled: true },
        [Validators.maxLength(100), Validators.email],
      ],
      genderId: [this.pilot.genderId ?? null],
      ethnicityId: [this.pilot.ethnicityId ?? null],
      pilotExperienceLevelId: [this.pilot.pilotExperienceLevelId ?? null, []],
      pilotStateId: [this.pilot.pilotStateId ?? null, []],
      state: [this.pilot.state ?? null, []],
      rating: [this.pilot.rating ?? null, []],
      upcomingMissions: [this.pilot.upcomingMissions ?? null, []],
      missionCount: [this.pilot.missionCount ?? null, []],
      perfectMissionPercentage: [this.pilot.perfectMissionPercentage ?? null, []],
      userId: [this.pilot.userId ?? null, []],
      isVeteran: [this.pilot.isVeteran ?? false, []],
      enableSMSReception: [this.pilot.enableSMSReception ?? false, []],
    });
  }

  submitForm() {
    if (this.form.invalid) {
      return;
    }

    const pilotInput = this.form.value as PilotUpdateDto;
    pilotInput.email = this.pilot.email;

    pilotInput.availabilityDescription = this.availabilities.items.find(
      x => x.id == pilotInput.availabilityId,
    )?.description;

    pilotInput.addressStreet = this.form.get('addressStreet').value;
    pilotInput.addressCity = this.form.get('addressCity').value;
    pilotInput.addressState = this.form.get('addressState').value;
    pilotInput.addressCountry = this.form.get('addressCountry').value;
    pilotInput.addressZipCode = this.form.get('addressZipCode').value;
    pilotInput.addressLat = this.form.get('addressLat').value;
    pilotInput.addressLng = this.form.get('addressLng').value;
    this.pilot = Object.assign(this.pilot, pilotInput);

    const request = this.pilotService.update(this.pilot.id, this.pilot);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm()),
      )
      .subscribe(
        res => {
          if (this.pilot.userId) {
            this.identityService.get(this.pilot.userId).subscribe(pilot => {
              if (pilot.extraProperties) {
                pilot.extraProperties.enableSMSReception = this.pilot.enableSMSReception;

                const updateUserDTO: IdentityUserUpdateDto = this.mapIdentityUserUpdateDto(
                  this.pilot,
                  pilot,
                );

                updateUserDTO.extraProperties.enableSMSReception = this.pilot.enableSMSReception;

                this.identityService.update(this.pilot.userId, updateUserDTO).subscribe({
                  next: result => {
                    this.getPilot();
                  },
                });
              }
            });
          }
        },
        err => {
          if (err.error.error.message) {
            this.toaster.error(`Flyguys::${err.error.error.message}`);
          }
        },
      );
  }

  private mapIdentityUserUpdateDto(
    pilotInput,
    currentLoginUserInfo: IdentityUserDto,
  ): IdentityUserUpdateDto {
    return {
      userName: pilotInput.email,
      name: currentLoginUserInfo.name,
      surname: currentLoginUserInfo.surname,
      email: pilotInput.email,
      phoneNumber: currentLoginUserInfo.phoneNumber,
      isActive: currentLoginUserInfo.isActive,
      shouldChangePasswordOnNextLogin: currentLoginUserInfo.shouldChangePasswordOnNextLogin,
      lockoutEnabled: currentLoginUserInfo.lockoutEnabled,
      roleNames: currentLoginUserInfo.roleNames,
      organizationUnitIds: [],
    } as IdentityUserUpdateDto;
  }

  create() {
    this.showForm();
  }

  private getPilotAvailabilityValues(): void {
    const pilotAvailabilityFilter = { state: enumState.Enabled } as GetAvailabilitiesInput;
    this.availabilityService
      .getList({
        ...pilotAvailabilityFilter,
      })
      .subscribe(res => {
        this.availabilities = res;
        for (const avb of this.availabilities.items) {
          this.availabilitiesDict[avb.id] = avb.description;
        }
      });
  }

  private getPilot(): void {
    this.pilotService.getProfile().subscribe(result => {
      this.pilot = result;

      if (this.pilot.userId) {
        this.identityService.get(this.pilot.userId).subscribe(pilot => {
          if (pilot.extraProperties) {
            if (pilot.extraProperties.enableSMSReception) {
              this.pilot.enableSMSReception = pilot.extraProperties.enableSMSReception;
            }
          }
        });
      }
    });
  }

  columnActions() {
    const columnActions: ColumnAction[] = [
      {
        actionIcon: 'edit',
        abpPermission: 'pilotsService.Pilots.Edit',
        action: { callAction: () => this.create() },
      },
    ];
    return columnActions;
  }

  handleSelectedAddress(address: ParsedGeocodedAddress) {
    this.setAddressValue('addressStreet', address.street);
    this.setAddressValue('addressCity', address.city);
    this.setAddressValue('addressState', address.state);
    this.setAddressValue('addressCountry', address.country);
    this.setAddressValue('addressZipCode', address.zip);
    this.setAddressValue('addressLat', address.lat + '');
    this.setAddressValue('addressLng', address.lng + '');
    if (address.country) {
      const country = this.dataCountries.items.find(
        c => c.description.toLowerCase().trim() === address.country.toLowerCase().trim(),
      );
      const state = this.dataStates.items.find(
        s => s.description.toLowerCase().trim() === address.state.toLowerCase().trim(),
      );
      if (country) {
        this.form.get('addressCountryId').patchValue(country.id);
        this.setStatesFor(country.id);
      }
      if (state) {
        this.form.get('addressStateId').patchValue(state.id);
      }
    }
  }

  setAddressValue(inputName: string, value: string) {
    if (value.trim() === '') {
      this.form.get(inputName).patchValue('');
    } else {
      this.form.get(inputName).patchValue(value);
    }
  }

  clearAddressForm(clear: boolean) {
    if (clear) {
      this.form.get('addressStreet').patchValue('');
      this.form.get('addressCity').patchValue('');
      this.form.get('addressState').patchValue('');
      this.form.get('addressStateId').patchValue('');
      this.form.get('addressCountry').patchValue('');
      this.form.get('addressCountryId').patchValue('');
      this.form.get('addressZipCode').patchValue('');
      this.form.get('addressLat').patchValue('');
      this.form.get('addressLng').patchValue('');
    }
  }

  validateForm(form: FormGroup): boolean {
    if (!form.valid) {
      for (let i in form.controls) {
        if (form.controls[i].invalid) {
          form.controls[i].markAsTouched();
          form.controls[i].markAsDirty();
          form.controls[i].setErrors({ incorrect: true });
        }
      }
      return false;
    }
    return true;
  }

  currentStates: any[] = [];

  /**
   * Handles a change in the location
   * @param location Location | null
   */
  handleLocationChange(location: Location | null) {
    console.log(location);
  }

  /**
   * Handles the selection on the country select
   * @param event MatSelectChange
   */
  handleCountrySelection(countryId) {
    const selectedCountryId: string = countryId;

    this.form
      .get('addressCountry')
      .patchValue(this.dataCountries.items.find(x => x.id == selectedCountryId)?.description ?? '');

    this.setStatesFor(selectedCountryId);
  }

  handleStateSelection(stateId) {
    const selectedStateId: string = stateId;

    this.form
      .get('addressState')
      .patchValue(this.dataStates.items.find(x => x.id == selectedStateId)?.description ?? '');
  }

  /**
   * Sets the current states based on the countryId
   * @param countryId
   */
  private setStatesFor(countryId: string) {
    this.currentStates = this.dataStates.items.filter((s: any) => s.countryId === countryId);
  }
}
