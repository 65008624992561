import type {
  CreateDirectoryInput,
  DirectoryContentDto,
  DirectoryContentRequestInput,
  DirectoryDescriptorDto,
  DirectoryDescriptorInfoDto,
  FolderPermissionResultModel,
  MoveDirectoryInput,
  RenameDirectoryInput,
  FilterMission,
  RootFolderDetailsDto,
  SftpUserCredentialsDto,
  SftpFileDto,
  SftpFileTransferDto,
  SftpTransferStatusDto,
  StartSftpFileTransferDto,
} from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirectoryDescriptorService {
  apiName = 'FileManagement';
  currentCaptureId: string;

  filterMission: FilterMission = undefined;

  create = (input: CreateDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor',
        body: input,
      },
      { apiName: this.apiName }
    );

  createIfNotExists = (input: CreateDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor/createIfNotExists',
        body: input,
      },
      { apiName: this.apiName }
    );

  createWithMissionFilters = (input: CreateDirectoryInput, missionFilter: FilterMission) =>
    this.restService.request<string, any>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/mission/${missionFilter.missionId}/capture/${missionFilter.captureId}/deliverable/${missionFilter.deliverableId}/structure`,
        body: `\"{ \\"FolderName\\":\\"${input.name}\\", \\"ParentId\\":\\"${input.parentId}\\"}\"`,
        headers: { 'Content-Type': 'application/json' },
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/file-management/directory-descriptor/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/${id}`,
      },
      { apiName: this.apiName }
    );

  getContent(input: DirectoryContentRequestInput, missionFilter: FilterMission = undefined) {
    let url = '/api/file-management/directory-descriptor';
    let params: any = {};
    if (missionFilter && missionFilter.deliverableId)
      url += `/mission/${missionFilter.missionId}/capture/${missionFilter.captureId}/deliverable/${missionFilter.deliverableId}/orderDetail/${missionFilter.orderDetailsId}`;
    else if (missionFilter)
      url += `/mission/${missionFilter.missionId}/capture/${missionFilter.captureId}/deliverable/00000000-0000-0000-0000-000000000000/orderDetail/${missionFilter.orderDetailsId}`;
    else
      params = {
        filter: input.filter,
        id: input.id,
        sorting: input.sorting,
        skipCount: input.skipCount,
        maxResultCount: input.maxResultCount,
      };

    return this.restService.request<any, PagedResultDto<DirectoryContentDto>>(
      {
        method: 'GET',
        url: url,
        params: params,
      },
      { apiName: this.apiName }
    );
  }

  getList = (parentId: string) =>
    this.restService.request<any, ListResultDto<DirectoryDescriptorInfoDto>>(
      {
        method: 'GET',
        url: '/api/file-management/directory-descriptor/sub-directories',
        params: { parentId },
      },
      { apiName: this.apiName }
    );

  move = (input: MoveDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor/move',
        body: input,
      },
      { apiName: this.apiName }
    );

  rename = (id: string, input: RenameDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getPermissions = (id: string) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/${id}/permissions`,
      },
      { apiName: this.apiName }
    );

  updatePermissions = (id: string, input: FolderPermissionResultModel) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/${id}/permissions`,
        body: input,
      },
      { apiName: this.apiName }
    );

  setCurrentCapture(captureId: string) {
    this.currentCaptureId = captureId;
  }

  getRoot = (missionId: string, deliverableId: string, captureId: string, orderDetailId: string) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/root/mission/${missionId}/deliverable/${deliverableId}/capture/${captureId}/orderDetail/${orderDetailId}`,
      },
      { apiName: this.apiName }
    );

  getRootFolderDetails = (
    missionId: string,
    deliverableId: string,
    captureId: string,
    orderDetailId: string
  ) =>
    this.restService.request<any, RootFolderDetailsDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/root/details/mission/${missionId}/deliverable/${deliverableId}/capture/${captureId}/orderDetail/${orderDetailId}`,
      },
      { apiName: this.apiName }
    );

  checkExistence = (name: string) => {
    return this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: "/api/file-management/directory-descriptor/exists",
        params: {
          name: name
        }
      },
      { apiName: this.apiName }
    );
  }

  getSftpCredentials = () =>
    this.restService.request<any, SftpUserCredentialsDto>(
      {
        method: 'POST',
        url: `/api/file-management/sftp-users/sftp-credentials`,
        body: null,
      },
      { apiName: this.apiName },
    );

  getSftpFiles = (prefix: string) =>
    this.restService.request<any, SftpFileDto[]>(
      {
        method: 'POST',
        url: `/api/file-management/sftp-users/files`,
        body: {
          prefix,
        },
      },
      { apiName: this.apiName },
    );

  transferSftpFiles = (input: SftpFileTransferDto) =>
    this.restService.request<any, StartSftpFileTransferDto>(
      {
        method: 'POST',
        url: `/api/file-management/sftp-users/transfer-files`,
        body: input,
      },
      { apiName: this.apiName },
    );

  getSftpTransferStatus = (input: string) =>
    this.restService.request<any, SftpTransferStatusDto>(
      {
        method: 'GET',
        url: `/api/file-management/sftp-transfer-statuses/${input}`,
      },
      { apiName: this.apiName },
    );

  constructor(private restService: RestService) { }
}
